import { createLogic } from 'redux-logic';
import {
    panelsLoad,
    panelsLoaded,
    applicationCreate,
    organizationSelect,
    generateApplicationList,
    loadedApplicationList,
    formFinderStart,
    licensesLoad,
    licensesLoaded,
    invoicesPay,
    failedPendingApplicationRequest,
} from '../RootActions';
import { apiGet, apiPost } from '../../api';
import { push } from 'connected-react-router';
import React from 'react';
import ApplicationActivity from '../../ApplicationActivity';
import { entityNotLinkedPath } from '../../EntityNotLinkedError';
import { getSortName } from '../../util';
import { applicationCreateFailed, formFinderAppCreateFailed, failedDependentRequest } from '../RootActions';

const loadPanels = createLogic({
    type: panelsLoad,
    latest: true,
    process({ action }, dispatch, done) {
        const page = action.payload;
        apiGet(`pages/${page}`)
            .then((result) => {
                dispatch(panelsLoaded(result.data));
            })
            .catch(() => {})
            .finally(done);
    },
});

const createApplication = createLogic({
    type: applicationCreate,
    latest: true,
    async process({ action }, dispatch, done) {
        const { data, getAccessToken, authorizationScopes, userProfile, dispatcher } = action.payload;
        const authToken = await getAccessToken(authorizationScopes.applications);
        const personId = userProfile?.personId ?? null;
        const form = (await apiGet(`forms/${data.formId}`, await getAccessToken(authorizationScopes.forms))).data;

        let payload = data;

        if (!data.context && !form.onBehalfOf) {
            payload = { ...data, context: { personId } };
        }

        if (!payload?.context?.personId) {
            if (form.existingEntityOnly || form.entityOptions === 'existingOnly') {
                // If the form is exclusively for an existing person then
                // direct them to the error page.
                dispatch(push(`${entityNotLinkedPath}`));
                done();

                return;
            }
        }

        apiPost('applications', payload, authToken)
            .then((result) => {
                const applicationId = result.data.id;
                dispatch(push(`/applications/${applicationId}`));
            })
            .catch((error) => {
                const errorResponse = error?.response?.data;
                if (errorResponse?.error?.statusCode === 403 && errorResponse?.error.message) {
                    // Determine the appropriate action for each calling component.
                    switch (dispatcher) {
                        case 'FormFinder':
                            dispatch(formFinderAppCreateFailed(errorResponse?.error.message));

                            break;
                        case 'ManageDependents':
                            dispatch(failedDependentRequest(errorResponse?.error.message));

                            break;
                        case 'ApplicantDashboard': {
                            dispatch(failedPendingApplicationRequest(errorResponse?.error.message));

                            break;
                        }
                        default:
                            // Default to PagePanel component.
                            dispatch(applicationCreateFailed(errorResponse?.error.message));
                    }
                }
            })
            .finally(done);
    },
});

const selectOrganization = createLogic({
    type: organizationSelect,
    latest: true,
    async process({ action }, dispatch, done) {
        const { data, userProfile, getAccessToken, authorizationScopes } = action.payload;

        const form = (await apiGet(`forms/${data.formId}`, await getAccessToken(authorizationScopes.forms))).data;
        if (
            userProfile &&
            userProfile.organizations &&
            userProfile.organizations.length > 0 &&
            form.entityOptions !== 'newOnly'
        ) {
            dispatch(
                push({
                    pathname: '/organizations',
                    state: {
                        formId: form.id,
                        entityOptions: form.existingEntityOnly ? 'existingOnly' : form.entityOptions,
                    },
                }),
            );
        } else {
            if (form.existingEntityOnly || form.entityOptions === 'existingOnly') {
                // If the form is exclusively for an existing entity then
                // direct them to the error page.
                dispatch(push(`${entityNotLinkedPath}`));
            } else {
                action.payload.data = { ...action.payload.data, context: { personId: null } };

                dispatch(applicationCreate({ ...action.payload }));
            }
        }

        done();
    },
});

const payInvoices = createLogic({
    type: invoicesPay,
    latest: true,
    async process({ action }, dispatch, done) {
        const { userProfile } = action.payload;
        if (userProfile) {
            dispatch(push('/invoices'));
        }
        done();
    },
});

const createApplicationList = createLogic({
    type: generateApplicationList,
    latest: true,
    async process({ action }, dispatch, done) {
        const { getAccessToken, authorizationScopes, userProfile, statusLabel } = action.payload;
        const authToken = await getAccessToken(authorizationScopes.applications);
        let applications;

        switch (statusLabel) {
            // Backwards compatability
            case undefined:
                applications = (await apiGet('applications', authToken)).data;
                break;
            case 'Started':
                applications = (
                    await apiGet('applications', authToken, null, {
                        params: {
                            filter: {
                                where: {
                                    and: [
                                        { status: { inq: ['Started', 'Completed'] } },
                                        {
                                            or: [{ assignee: null }, { assignee: '<userProfileId>' }],
                                        },
                                    ],
                                },
                            },
                        },
                    })
                ).data;
                break;

            case 'Submitted':
                applications = (
                    await apiGet('applications', authToken, null, {
                        params: {
                            filter: {
                                where: {
                                    and: [
                                        { status: { inq: ['Submitted'] } },
                                        {
                                            or: [{ assignee: null }, { assignee: '<userProfileId>' }],
                                        },
                                    ],
                                },
                            },
                        },
                    })
                ).data;
        }

        if (applications && applications.length > 0) {
            const applicationList = applications.map((application) => (
                <ApplicationActivity
                    getAccessToken={getAccessToken}
                    authorizationScopes={authorizationScopes}
                    key={application.id}
                    applicationId={application.id}
                    status={application.status}
                    formName={application.formName}
                    assignee={application.assignee}
                    userId={application.userId}
                    userProfile={userProfile}
                ></ApplicationActivity>
            ));
            dispatch(loadedApplicationList({ resultList: applicationList }));
            done();
        } else {
            const emptyMessage = 'No Recent Activity';
            dispatch(loadedApplicationList({ noDataMessage: emptyMessage }));
            done();
        }
    },
});

const startFormFinder = createLogic({
    type: formFinderStart,
    latest: true,
    process({ action }, dispatch, done) {
        const formFinderId = action.payload.data.id;
        dispatch(push(`/formFinders/${formFinderId}`));
        done();
    },
});

const loadLicenses = createLogic({
    type: licensesLoad,
    latest: true,
    async process({ action }, dispatch, done) {
        const { getAccessToken, authorizationScopes } = action.payload;

        try {
            const authToken = await getAccessToken(authorizationScopes.licenses);
            const result = await apiGet('licenses/personal', authToken);
            const licenses = result.data.map((license) => ({
                ...license,
                person: {
                    ...license.person,
                    name: getSortName(license.person.name),
                },
            }));

            dispatch(licensesLoaded(licenses));
        } finally {
            done();
        }
    },
});

export default [
    loadPanels,
    createApplication,
    selectOrganization,
    createApplicationList,
    startFormFinder,
    loadLicenses,
    payInvoices,
];
